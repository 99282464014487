import passwordResets from './passwordResets';
import hrManagers from './hrManagers';
import whitelists from './whitelists';
import corporations from './corporations';
import challenges from './challenges';
import challengeTemplates from './challengeTemplates';
import { ChampionshipResource } from './challenges';

import { Menu as MenuType } from '../@types/common';
import corporationsGoogle from './corporationsGoogle';
import whitelistGoogleWorkSpace from './whitelistGoogleWorkSpace';
import synchronization from './synchronization';
import contacts from './contacts';

export const Menu: MenuType = [
  whitelists,
  challenges,
  contacts
];

export default {
  contacts,
  passwordResets,
  hrManagers,
  whitelists,
  whitelistGoogleWorkSpace,
  corporations,
  corporationsGoogle,
  challenges,
  challengeTemplates,
  ChampionshipResource,
  synchronization,
};
