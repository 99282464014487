import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  downloadButton: {
    borderRadius: '20px',
    margin: '15px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  pageTitle: {
    marginBottom: '10px',
  },
  container: {
    padding: 32,
  },
});

const Contacts = () => {
  const styles = useStyles();
  const t = useTranslate();

  return (
    <div className={styles.container}>
      <Typography className={styles.pageTitle} variant="h4">
        {t('UseContact.title')}
      </Typography>

      <ul>
        <li>
          <strong style={{ color: '#3c0cbf' }}>{t('UseContact.1')} : </strong>
          csm@gymlib.com
        </li>

        <li>
          <strong style={{ color: '#3c0cbf' }}>{t('UseContact.2')} : </strong>
          care@gymlib.com
        </li>

        <li>
          <strong style={{ color: '#3c0cbf' }}>{t('UseContact.3')} : </strong>
          client@gymlib.com
        </li>
      </ul>
    </div>
  );
};

export default Contacts;
