import { IResourceExport } from '../../@types/dataProvider';
import SendIcon from '@material-ui/icons/Send';
import Contacts from './Contacts';
import contactsProvider from './dataProvider';

export default {
  name: 'Contacts',
  dataProvider: {
    getOneName: 'company',
    provider: contactsProvider,
  },
  icon: SendIcon,
  resources: {
    list: Contacts,
  },
} as IResourceExport;
